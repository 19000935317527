import * as imageConversion from 'image-conversion';

export default function (file) {
  return new Promise((resolve) => {
    imageConversion.compress(file, {
      type: file.type,
    }).then((result) => {
      resolve(result);
    });
  });
}
